import { Link } from "react-router-dom";
import BGImg2 from "../../assets/img/bg/services-bg.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL, kullaniciId } from "../../App";
import { useGlobalState } from "../../useGlobalState";

const Service = () => {
  const { globalState, setGlobalState } = useGlobalState();

  const [hizmet, setHizmet] = useState([]);
  const [data, setData] = useState(null);
  const [dataone, setDataone] = useState(null);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/hizmet_sayfasi_ayarlar?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          // Veriyi state'e set etme
          setHizmet(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  const [hizmetler, setHizmetler] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/hizmetler?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setHizmetler(response.data.data);
            var dataarr = [];
            var data1arr = [];
            for (let i = 0; i < response.data.data.length; i++) {
              if (i % 2 === 1) {
                dataarr.push(response.data.data[i]);
              } else {
                data1arr.push(response.data.data[i]);
              }
            }
            setData(dataarr);
            setDataone(data1arr);
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  return (
    <section
      id="service-details2"
      className="service-details-two pb-105 p-relative"
      style={{
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${BGImg2})`,

      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            {hizmet && hizmet[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
              <div className="section-title center-align mb-50 text-center">
                <h5>{hizmet && hizmet[0].translations?.filter((e) => e.languages_code == globalState.lang)[0]?.kapak_yazisi}</h5>
                <h2>{hizmet && hizmet[0].translations?.filter((e) => e.languages_code == globalState.lang)[0]?.baslik}</h2>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="about-content s-about-content">
              <ul className="sr-tw-ul ">


                {data &&
                  data.map((item) => (
                    <li key={item.id}>
                      <div className="icon-right">
                        <img
                          src={baseURL + "assets/" + item?.icon?.id}
                          alt="icon01"
                        />
                      </div>
                      <div className="text">
                        <h4>
                          <Link to={"/hizmetler" + item?.hizmet_link}>
                            {item?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.hizmet_baslik}
                          </Link>
                        </h4>
                        <p>{item?.translations?.filter((b) => b.languages_code === globalState.lang)[0]?.hizmet_kucuk_aciklama}</p>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
            <div className="sd-img">
              {/* <img
                src={hizmet && baseURL + "assets/" + hizmet[0].kapak_resmi?.id}
                alt="img"
              /> */}
              {hizmet && hizmet[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                <img src={`${baseURL}assets/${hizmet[0].kapak_resmi?.id}`} alt="" />
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="about-content s-about-content">
              <ul className="sr-tw-ul sr-thr-ul">
                {dataone && dataone.map((item) => (
                  <li key={item.id}>
                    <div className="icon">
                      <img src={hizmet && baseURL + "assets/" + item.icon?.id}
                        alt="icon01" />
                    </div>
                    <div className="text pt-10">
                      <h4>
                        <Link to={"/hizmetler" + item?.hizmet_link}>
                          {item?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.hizmet_baslik}
                        </Link>
                      </h4>
                      <p> {item?.translations?.filter((b) => b.languages_code === globalState.lang)[0]?.hizmet_kucuk_aciklama}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
