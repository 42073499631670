import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import BGImg from "../../assets/img/bg/footer-bg.png";
import Img2 from "../../assets/img/icon/ft-icon01.png";

import { baseURL, kullaniciId } from "../../App";
import axios from "axios";
import { useGlobalState } from "../../useGlobalState";
import Icon from "../Icon";


const Main = () => {

  const [menuItems, setMenuItems] = useState([]);
  const [iletisim, setIletisim] = useState([]);
  const [Ayarlar, setAyarlar] = useState([]);


  const { globalState, setGlobalState } = useGlobalState();



  useEffect(() => {
    try {
      axios.get(`${baseURL}items/header?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setMenuItems(response.data.data);
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);


  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };



  const [altBolum, setAltBolum] = useState(false);

  const fetchAltBolum = async () => {
    try {
      const response = await axios.get(`${baseURL}items/alt_bolum_icerikler?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAltBolum(response.data.data);
        // console.log(response.data.data, "altbolum.data.data");
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };



  const [icon, setIcon] = useState([])


  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/sosyal_medya_iconlar?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setIcon(response.data.data);
            // console.log(response.data.data, "ikonlar.data.data");
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);




  const fetchLogoData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/Ayarlar?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAyarlar(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };


  useEffect(() => {
    fetchContactData();
    fetchAltBolum()
    fetchLogoData();

  }, []);

  return (
    <>
      <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#252525", backgroundImage: `url(${BGImg})` }}>
        {altBolum && altBolum[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (

          <div className="footer-top">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-4 col-lg-4 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title mb-40">
                      <img src={`${baseURL}assets/${Ayarlar[0].logo?.id}`} alt="" />
                    </div>
                    <div className="footer-link">
                      <p>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.alt_bolum_aciklama}</p>
                      <div className="f-contact">
                        <ul>
                          <li>
                            <div className="contact-box">
                              <div className="icon">
                                <img src={Img2} alt="logo" />
                              </div>
                              <div className="text">
                                <p>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik}</p>
                                <p>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.gun1} </p>
                                <p>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.gun2} </p>

                                {/* <strong>Pazartesi - Cuma:</strong>
                              <p>9:00 - 22:00</p>
                              <strong>Cumartesi - Pazar:</strong>
                              <p>9:00 - 20:00</p> */}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-2 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                      <h2>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.yazi}</h2>
                    </div>
                    <div className="footer-link">
                      <ul>
                        {menuItems && menuItems.map((e) => (
                          <li key={e.id} className="has-sub">
                            <>
                              <Link to={e?.link}>{e?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.title}</Link>
                            </>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                      <h2>{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.bulten}</h2>
                    </div>
                    <div className="footer-link">
                      <div
                        className="subricbe p-relative"
                        data-animation="fadeInDown"
                        data-delay=".4s"
                      >
                        <form
                          action="news-mail.php"
                          method="post"
                          className="contact-form "
                        >
                          <input
                            type="text"
                            id="email2"
                            name="email2"
                            className="header-input"
                            placeholder={altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.form_yazi}
                            required
                          />
                          <button className="btn header-btn">
                            <i className="fas fa-location-arrow"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="footer-social mt-10" style={{ display: "flex" }}>
                    {icon && icon.map((e) => (
                      <li key={e.id}>
                        <a href={e?.link} target="blank">
                          <Icon
                            url={`${baseURL}assets/${e.icon?.id}`}
                            size={25}
                            fill={e?.renk}
                          />
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row align-items-center copyright-wrap">
                <div className="col-lg-6">
                  <div className="copy-text text-center">
                    <Link to="https://yunsoftyazilim.com/" target="_blank">{altBolum && altBolum[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.copyright}</Link>

                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="copay-contact" style={{ display: "flex" }}>

                    <div className="c-contact" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      <div className="icon" style={{ margin: "15px", }}>
                        <ul >
                          {icon && icon.slice(0, 1).map((e) => (
                            <li key={e.id}>
                              <a href={e?.link} target="blank">
                                <Icon
                                  url={`${baseURL}assets/${e.icon?.id}`}
                                  size={25}
                                  fill={e?.renk}
                                />
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {iletisim && iletisim[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                        <div className="text">
                          <span>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.mail_baslik}</span>
                          <p>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.mail}</p>
                        </div>
                      )}
                    </div>

                    <div className="c-contact" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      <div className="icon" style={{ margin: "15px", }}>
                        <ul>
                          {icon && icon.slice(1, 2).map((e) => (
                            <li key={e.id}>
                              <a href={e?.link} target="blank">
                                <Icon
                                  url={`${baseURL}assets/${e.icon?.id}`}
                                  size={25}
                                  fill={e?.renk}
                                />
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {iletisim && iletisim[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                        <div className="text">
                          <span>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.telefon_baslik}</span>
                          <p>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.telefon}</p>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </footer>
    </>
  );
};

export default Main;
