import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import BGIMG from "../../assets/img/bg/bdrc-bg.png";
import { baseURL, kullaniciId } from "../../App";
import { useGlobalState } from "../../useGlobalState";

const Bredcrumb = ({ title, subtitle, breadcrumb_resim }) => {
  const { globalState } = useGlobalState();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`${baseURL}items/header?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        if (response.data && Array.isArray(response.data.data)) {
          setMenuItems(response.data.data);
        } else {
          console.error("Invalid data format received from Directus.");
        }
      } catch (error) {
        console.error("Error fetching data from Directus:", error);
      }
    };

    fetchMenuItems();
  }, []);




  const [breadcrumb, setBreadcrumb] = useState(null);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/sayfa_breadcrumb?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`)
        .then((response) => {
          if (response.data) {
            setBreadcrumb(response.data.data);
            // console.log(response.data.data, "breadcrumb.data.data");
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);




  const getMenuItemTitle = (headerId) => {
    const menuItem = menuItems.find((item) => item.translations.some((t) => t.languages_code === globalState.lang && t.header_id === headerId));
    return menuItem?.translations.find((t) => t.languages_code === globalState.lang && t.header_id === headerId)?.Title || "";
  };

  return (
    <section className="breadcrumb-area d-flex align-items-center"
      //  style={{ backgroundImage: `url(${BGIMG})` }}
      style={{ backgroundImage: `url(${breadcrumb_resim})` }}

    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12">
            <div className="breadcrumb-wrap text-left">
              <div className="breadcrumb-title">
                <h2>{title}</h2>
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      {breadcrumb && breadcrumb[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                        <li className="breadcrumb-item">
                          <Link to="/">{breadcrumb[0].translations?.filter((a) => a.languages_code === globalState.lang)[0]?.baslik}</Link>
                        </li>
                      )}

                      <li className="breadcrumb-item active" aria-current="page">
                        {subtitle}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bredcrumb;
