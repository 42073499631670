import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL, kullaniciId } from "../../App";
import { useGlobalState } from "../../useGlobalState";

const Feature = () => {
  const { globalState, setGlobalState } = useGlobalState();

  const [icerik, setIcerik] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/icerik?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        .then((response) => {
          setIcerik(response.data.data);
          console.log(response.data.data, "icerik");
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  return (
    <>
      <section className="feature-area p-relative fix">
        <div className="containe-fluid">
          <div className="row">
            {icerik && icerik.map((e) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={e.id}>
                <div className="feature-box">
                  <div className="text">
                    <br />
                    <h5>
                      {e?.translations?.filter((b) => b.languages_code === globalState.lang)[0]?.baslik}
                    </h5>
                    <h6>
                      {e?.translations?.filter((b) => b.languages_code === globalState.lang)[0]?.yazi}
                    </h6>
                    <Link to={e?.butonlink}>
                      {e?.translations?.filter((b) => b.languages_code === globalState.lang)[0]?.butonyazi}
                    </Link>
                  </div>
                  <img src={`${baseURL}assets/${e.resim?.id}`} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
