import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL, kullaniciId } from "../../App";
import { useGlobalState } from "../../useGlobalState";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

const Main = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [mobile, setMobile] = useState(false);
  const [Ayarlar, setAyarlar] = useState(false);

  const { globalState, setGlobalState } = useGlobalState();

  const [isActiveList, setIsActiveList] = useState([]);

  // Toggle durumunu güncelleyen fonksiyon
  const handleToggle = (index) => {
    const updatedList = [...isActiveList];
    updatedList[index] = !updatedList[index];
    setIsActiveList(updatedList);
  };

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/header?fields=*.*&filter[user_created][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setMenuItems(response.data.data);
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${baseURL}items/languages?fields=*`);
        setLanguages(response.data.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const languageDetection = () => {
      //localstorage a dil kaydedilmiş mi bak
      if (languages.length > 0) {
        const storedLang = localStorage.getItem("lang");
        console.log(storedLang, "storedLang");
        //kaydedildiyse kaydedilen dili ayarla
        if (storedLang) {
          changeLang(storedLang);
        }
        //kaydedilmediyse tarayıcı dlini ayarla
        else {
          const browserLanguage =
            window.navigator.language || window.navigator.userLanguage;
          console.log(browserLanguage, "browser");
          console.log(languages);
          const newlang = languages.filter(
            (e) => e.code.slice(0, 2) === browserLanguage.slice(0, 2)
          );
          console.log(newlang, "newlang");
          if (newlang.length > 0) {
            changeLang(newlang[0].code);
          } else {
            changeLang("en-US");
          }
        }
      }
    };
    languageDetection();
  }, [languages]);

  const changeLang = (a) => {
    localStorage.setItem("lang", a);
    setGlobalState((pre) => ({ ...pre, lang: a }));
  };
  if (globalState.lang);



  useEffect(() => {
  }, [globalState]);




  const fetchLogoData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/Ayarlar?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAyarlar(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };

  useEffect(() => {
    fetchLogoData();
  }, [])


  return (
    <>
      <header
        className="header-area header-three"
        style={{ backgroundColor: "black" }}
      >
        <div id="header-sticky" className="menu-area" style={{ backgroundColor: "black", }}>
          <div className="container" >
            <div className="second-menu"

            >
              <div className="row align-items-center">
                <div className="col-xl-2 col-4 col-lg-2">
                  <div className="logo">
                    {Ayarlar && Ayarlar[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                      <Link to="/">
                        <img src={`${baseURL}assets/${Ayarlar[0].logo?.id}`} alt="" />
                      </Link>
                    )}
                  </div>
                </div>




                <div className="col-xl-7 col-3 col-lg-7">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        {menuItems &&
                          menuItems.map(
                            (e) =>
                              !e.altmenu && (
                                <li
                                  key={e.id}
                                  className={
                                    e.menuler.length > 0 ? "has-sub" : ""
                                  }
                                >
                                  {!e.altmenu && (
                                    <>
                                      <Link to={e?.link}
                                      // target={e.yenisekme ? "_blank" : "_self"}
                                      >
                                        {
                                          e?.translations?.filter(
                                            (b) =>
                                              b.languages_code ===
                                              globalState.lang
                                          )[0]?.title
                                        }
                                      </Link>
                                      {e.menuler.length > 0 && (
                                        <ul>
                                          {e.menuler.map((subItem) => (
                                            <li key={subItem.id}>
                                              {menuItems
                                                .filter(
                                                  (a) => a.id == subItem.id
                                                )
                                                .map((b) => (
                                                  <Link to={b?.link} key={b.id}
                                                  // target={e.yenisekme ? "_blank" : "_self"}
                                                  >
                                                    {
                                                      b?.translations?.filter(
                                                        (c) =>
                                                          c.languages_code ==
                                                          globalState.lang
                                                      )[0]?.title
                                                    }
                                                  </Link>
                                                ))}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </>
                                  )}
                                </li>
                              )
                          )}
                      </ul>
                    </nav>
                  </div>
                </div>


                <div className="col-xl-3 col-lg-3 col-5  d-lg-block text-right">
                  <div className="login" >
                    <Dropdown as={ButtonGroup} size="xs" style={{ right: 30, top: 5 }} >
                      <Dropdown.Toggle
                        className=" bg-transparent btn-outline-secondary border-0 "
                        id="lng-dropdown"
                        style={{ fontSize: "1.5rem" }}
                      >
                        <i className="fa fa-globe" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {languages && languages.map(e => (<Dropdown.Item key={e.code} onClick={() => changeLang(e.code)}>
                          {e.name}
                        </Dropdown.Item>))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>



                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#"
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        onClick={() => setMobile(mobile === true ? false : true)}
                        style={{
                          right: "0",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                          color: "#fff"
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <>
                            <span style={{ backgroundColor: "white" }}>
                              <span style={{ backgroundColor: "white" }}>
                                <span style={{ backgroundColor: "white" }}></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>



                      <nav className="mean-nav">
                        {mobile && (
                          <ul>
                            {menuItems.map(
                              (e, index) =>
                                !e.altmenu && (
                                  <React.Fragment key={e.id}>
                                    <li
                                      key={e.id}
                                      className={e.menuler.length > 0 ? "has-sub" : ""}
                                    >
                                      <Link
                                        to={!e.altmenu && e?.link}
                                        onClick={() => (e.altmenu ? handleToggle(index) : null)}
                                      >
                                        {e?.translations?.filter(
                                          (b) => b.languages_code === globalState.lang
                                        )[0]?.title}
                                      </Link>
                                      {e.menuler?.length > 0 && (
                                        <>
                                          {isActiveList[index] && (
                                            <ul>
                                              {e.menuler.map((subItem) => (
                                                <li key={subItem.id}>
                                                  {menuItems
                                                    .filter((a) => a.id == subItem.id)
                                                    .map((b) => (
                                                      <Link to={b?.link} key={b?.id}>
                                                        {b?.translations?.filter(
                                                          (c) =>
                                                            c.languages_code ===
                                                            globalState.lang
                                                        )[0]?.title}
                                                      </Link>
                                                    ))}
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                          <Link
                                            to="#"
                                            className={`mean-expand ${isActiveList[index] &&
                                              "mean-clicked"
                                              }`}
                                            onClick={() => handleToggle(index)}
                                            style={{ fontSize: "18px" }}
                                          >
                                            {isActiveList[index] ? "-" : "+"}
                                          </Link>
                                        </>
                                      )}
                                    </li>
                                  </React.Fragment>
                                )
                            )}
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
