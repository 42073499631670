import BGImg3 from "../../assets/img/bg/ap-bg.png";
import React, { useState, useEffect } from "react";
import Img from "../../assets/img/bg/open-box-img.png";
import Map from "../Mencontact/Map";
import axios from "axios";
import { baseURL, kullaniciId } from "../../App";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useGlobalState } from "../../useGlobalState";





const Appointment = () => {

  const { globalState, setGlobalState } = useGlobalState();

  const [iletisim, setIletisim] = useState([]);
  const [saatler, setSaatler] = useState([]);

  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };



  const [altBolum, setAltBolum] = useState(false);


  const fetchAltBolum = async () => {
    try {
      const response = await axios.get(`${baseURL}items/alt_bolum_icerikler?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAltBolum(response.data.data);
        // console.log(response.data.data, "altbolum.data.data");
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };



  useEffect(() => {
    fetchContactData();
    fetchAltBolum();
  }, []);




  return (
    <>
      <section
        id="booking"
        className="booking-area p-relative pt-120 pb-120"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BGImg3})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-bg">
                {iletisim && iletisim[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
                  <div className="section-title center-align">
                    <h5>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.sayfa_basligi}</h5>
                    <h1>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik_2}</h1>
                    <p>{iletisim && iletisim[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.yazi}</p>
                  </div>
                )}

                <Map />

              </div>
            </div> <br />
            <div className="col-lg-6 mt-5">
              {altBolum && altBolum[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (

                <div className="opening-time mt-5">
                  <div className="open-img">
                    <img src={Img} alt="icon01" />
                  </div>
                  <h3>{altBolum[0].translations?.filter((b) => b.languages_code === globalState.lang)[0]?.baslik}</h3>

                  <div className="text">
                    {altBolum[0].translations?.filter((b) => b.languages_code === globalState.lang)[0]?.gun1}
                  </div>
                  <div className="text">
                    {altBolum[0].translations?.filter((b) => b.languages_code === globalState.lang)[0]?.gun2}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Appointment;
