import React from 'react'

function Map() {
    return (
        <>
            <div className="map fix" style={{ background: "#F5F5F5" }}>
                <div className="container-flud">
                    <div className="row">
                        {/* <div className="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.0193370303086!2d28.97074200160837!3d41.024832887862495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9e70b252953%3A0xf7b9e6dfc2edda95!2zWWnEn2l0YmHFnyDEsMWfIE1lcmtlemk!5e0!3m2!1str!2str!4v1704279677300!5m2!1str!2str" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div> */}
                        <div className="col-lg-12">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3072.570264641837!2d28.9657914!3d40.2111168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca15a1c4ff7b11%3A0xa3dd80b598884a20!2syunsoft%20yaz%C4%B1l%C4%B1m!5e0!3m2!1sen!2str!4v1704279677300!5m2!1sen!2str"
                                width={600}
                                height={450}
                                style={{ border: 0 }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Map