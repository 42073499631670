import React, { createContext, useContext, useState } from "react";

const GlobalStateContext = createContext();

// 2. Global state'i sağlayacak bir provider oluşturun
export const GlobalStateProvider = ({ children }) => {
    const [globalState, setGlobalState] = useState({
        lang: "",
    });

    return (
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

// 3. useContext kullanarak global state'e erişmek için bir özel bir hook oluşturun
export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("Global state is on wrong place");
    }
    return context;
};