import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL, kullaniciId } from "../../App";
import { useGlobalState } from "../../useGlobalState";

function Welness() {
  const [anaekran, setAnaekran] = useState([]);
  const { globalState, setGlobalState } = useGlobalState();

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/anaekran?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setAnaekran(response.data.data);
            // console.log(response.data.data, "anaekran.data.data");

          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  return (
    <>
      <section id="home" className="slider-area fix p-relative">
        <div
          className="slider-active"
        // style={{ background: "#0000" }}
        >
          {anaekran && anaekran[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (
            <div
              className="single-slider slider-bg d-flex align-items-center"
              style={{
                backgroundSize: "cover",
                // backgroundImage: `url(${baseURL + "assets/" + anaekran.resim?.id})`,
              }}
            >
              <div className="container">
                <div className="row ">




                  <div className="col-lg-6 col-md-6"
                  // style={{ marginLeft: "1000px" }}
                  >
                    <div className="slider-content s-slider-content mt-150" >

                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        {anaekran[0].translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.baslik}
                      </h5>
                      <h4
                        data-animation="fadeInUp"
                        data-delay=".4s"
                        style={{ color: "rgb(136, 76, 210)" }}
                      >
                        {anaekran[0].translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.altbaslik}
                      </h4>
                      <p
                        data-animation="fadeInUp"
                        data-delay=".6s"
                        style={{ color: "black" }}
                      >
                        {anaekran[0].translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.yazi}
                      </p>

                      <div className="slider-btn mt-30">
                        <Link to={anaekran[0].translations?.filter(b => b.languages_code === globalState.lang)[0]?.solbutonlink} className="btn ss-btn mr-15">
                          {anaekran[0].translations?.filter(b => b.languages_code === globalState.lang)[0]?.solbutonyazi}
                        </Link>

                        {/* <Link to={anaekran?.solbutonlink}>{anaekran?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.solbutonyazi}</Link> */}
                        <Link to={anaekran[0].translations?.filter(b => b.languages_code === globalState.lang)[0]?.sagbutonlink} className="btn ss-btn active">
                          {anaekran[0].translations?.filter(b => b.languages_code === globalState.lang)[0]?.sagbutonyazi}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6  mt-5">
                    <img src={`${baseURL}assets/${anaekran[0].resim?.id}`} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Welness;
