import React, { useState, useEffect } from "react";
import axios from "axios"; import Bredcrumb from '../HomeMain/Bredcrumb'
import Appointment from '../HomeMain/Appointment'
import Service from '../HomeMain/Service'
import { useGlobalState } from "../../useGlobalState";
import { baseURL, kullaniciId } from "../../App";


const Main = () => {

  const { globalState, setGlobalState } = useGlobalState();

  const [hizmet, setHizmet] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/hizmet_sayfasi_ayarlar?fields=*.*&filter[kullanici][_eq]=${kullaniciId}`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          // Veriyi state'e set etme
          setHizmet(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);


  return (
    <>
      {hizmet && hizmet[0]?.translations?.filter(e => e.languages_code === globalState.lang)[0] && (

        <Bredcrumb title={hizmet && hizmet[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.kapak_yazisi}
          subtitle={hizmet && hizmet[0].translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik}
          breadcrumb_resim={`${baseURL}assets/${hizmet[0].breadcrumb_kapak?.id}`}

        />
      )}
      <div className="service-main-block">
        <Service />
      </div>
      <Appointment />
      {/* <div className="testimonial-block">
        <Testimonial />
      </div> */}
    </>
  )
}

export default Main