import React from "react";
import About from "../HomeMain/About";
import Appointment from "../HomeMain/Appointment";
import Service from "../HomeMain/Service";
import Feature from "../HomeMain/Feature";
import Welness from "../HomeMain/Welness";

const Main = () => {
  return (
    <>
      <main>
        <Welness />
        <Feature />
        <About />
        <Service />
        <Appointment />
        {/* <Product />
        <Pricing />
        <Gallery />
        <Testimonial />
        <Blog /> */}
      </main>
    </>
  );
};

export default Main;
